<template>
  <div class="ink-video-container">
    <video src="ink.mp4" class="ink-video" autoplay loop muted></video>
    <div class="ink-overlay">
      <div>
        <img src="logo.png" alt="JCINC Logo" class="ink-banner-logo" />
        <!-- <h2>An Innovation in Innovation</h2> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.ink-video {
  min-width: 100%;
  min-height: 100%;
  margin-bottom: 230px;
  position: relative;
  z-index: 0;
}
.ink-video-container {
  height: 460px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.ink-overlay {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ink-banner-logo {
  max-width: 90vw;
}
</style>

<script>
export default {
  name: "InkBanner",
};
</script>
