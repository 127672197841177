<template>
  <div class="jcinc-score-container p-5">
    <h2>JCINC Score</h2>
    <h3>Innovating scoring away from the spreadsheet</h3>
    <br />
    <p class="blockquote">
      While the advanced formulas and generalisations of spreadsheets mean they
      are widely used for scorekeeping applications, specially designed systems
      allow for display of results live and up to date while reducing the chance
      of human error.
    </p>
    <img
      src="jcinc-score-pencil.svg"
      class="w-50 p-3"
      alt="JCINC Score pencil clipart"
    />
    <br />
    <a href="https://sport.jcinc.co.uk" class="fs-2" rel="noreferrer"
      >Open App</a
    >
  </div>
</template>

<style scoped>
h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2rem;
}

.jcinc-score-container {
  align-content: center;
  text-align: center;
}
</style>

<script>
export default {
  name: "JCINCScoreAD.vue",
};
</script>
