<template>
  <nav id="nav" class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="logo.png" alt="JCINC Logo" style="width: 130px;" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <!-- <a class="nav-link active" aria-current="page" href="#">Home</a> -->
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">About</router-link>
          </li>
          <li class="nav-item">
            <a
              href="https://www.jcinc.co.uk/shop"
              target="_blank"
              class="nav-link"
              rel="noreferrer"
              >Shop</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://support.jcinc.co.uk"
              target="_blank"
              class="nav-link"
              rel="noreferrer"
              >Support</a
            >
          </li>
        </ul>
        <a
          href="https://accounts.jcinc.co.uk"
          target="_blank"
          class="nav-link"
          rel="noreferrer"
          >Sign In
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MainNav",
};
</script>
