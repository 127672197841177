<template>
  <footer class="bg-light text-center text-lg-start">
    <!-- Copyright -->
    <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
      © 2020
      <a class="text-dark" href="https://www.jcinc.co.uk/">JCINC Technologies</a>
      , Do Not Distribute
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>
