<template>
  <div class="home">
    <InkBanner/>
    <JCINCScoreAD/>
  </div>
</template>

<script>
// @ is an alias to /src
import InkBanner from '@/components/InkBanner.vue'
import JCINCScoreAD from '@/components/JCINCScoreAD.vue'

export default {
  name: 'Home',
  components: {
    InkBanner,
    JCINCScoreAD
  }
}
</script>
